import { Component, OnDestroy, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { interval, Subject, Subscription } from 'rxjs';
import { SharedService } from './shared.service';
import {UserCartDetailsViewModel} from "../../projects/Shared/BusinessEntities/user-cart-details-view-model";
import {CommmonDataFilterService} from "../../projects/Shared/BusinessServices/common-data-filter.service";
import { AuthService } from './auth.service';
import { SecurityKeyConst } from 'projects/autofacets-infrastructure/src/Autofacets.Infrastructure.Common/Constant/global.constant';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  isIframe = true;
  loginDisplay = false;
  isShowMainHeader = true;
  private readonly _destroying$ = new Subject<void>();
  isDashboardActive = false;
  redirectCount = 0;
  PackagePortalAccessText: string = '';
  displayedBrandName: string = '';
  userCartDetails: UserCartDetailsViewModel;
  private intervalSubscription: Subscription;
  isTokenAvailable: boolean = false;
  constructor(
    public sharedService: SharedService,
    private commonDataFilterService: CommmonDataFilterService,
    private authService:AuthService
  ) {
    this.sharedService.isExternalUser = window.location.href.split('/')[3] == 'EXT';
    this.setAppConfiguration();
  }

  get brandName(): string {
    if (this.commonDataFilterService.selectedBrandName != this.displayedBrandName) {
      if (this.commonDataFilterService.themeAdded) {
        let last = document.getElementsByTagName('head')[0].childNodes[this.commonDataFilterService.themePosition];
        last.parentNode.removeChild(last);
      }
      this.commonDataFilterService.themePosition = document.getElementsByTagName('head')[0].childNodes.length;
      this.displayedBrandName = this.commonDataFilterService.selectedBrandName;
      switch (this.commonDataFilterService.selectedBrandName) {
        case "Peugeot": {
          this.setLoadCssFile("../../../assets/css/theme-peu.css");
          this.commonDataFilterService.themeAdded = true;
          break;
        }
        case "Mercedes-Benz": {
          this.setLoadCssFile("../../../assets/css/theme-mer.css");
          this.commonDataFilterService.themeAdded = true;
          break;
        }
        case "smart": {
          this.setLoadCssFile("../../../assets/css/theme-smart.css");
          this.commonDataFilterService.themeAdded = true;
          break;
        }
        case "Opel": {
          this.setLoadCssFile("../../../assets/css/theme-opel.css");
          this.commonDataFilterService.themeAdded = true;
          break;
        }
        case "Kia": {
          this.setLoadCssFile("../../../assets/css/theme-kia.css");
          this.commonDataFilterService.themeAdded = true;
          break;
        }
        case "Jeep": {
          this.setLoadCssFile("../../../assets/css/theme-jeep.css");
          this.commonDataFilterService.themeAdded = true;
          break;
        }
        case "Fiat": {
          this.setLoadCssFile("../../../assets/css/theme-fiat.css");
          this.commonDataFilterService.themeAdded = true;
          break;
        }
        case "Fiat Professional": {
          this.setLoadCssFile("../../../assets/css/theme-fiat-prof.css");
          this.commonDataFilterService.themeAdded = true;
          break;
        }
        case "DS": {
          this.setLoadCssFile("../../../assets/css/theme-ds.css");
          this.commonDataFilterService.themeAdded = true;
          break;
        }
        case "Citroën": {
          this.setLoadCssFile("../../../assets/css/theme-cit.css");
          this.commonDataFilterService.themeAdded = true;
          break;
        }
        case "Alfa Romeo": {
          this.setLoadCssFile("../../../assets/css/theme-alf.css");
          this.commonDataFilterService.themeAdded = true;
          break;
        }
        default: {
          this.commonDataFilterService.themeAdded = false;
          break;
        }
      }
    }

    return this.commonDataFilterService.selectedBrandName;
  }

  async ngOnInit() {
    if(this.sharedService.isExternalUser) {
      //Set interval to 1000ms (1 second), change as needed
        const intervalTime = SecurityKeyConst.intervalTimeForToken;
        //Subscribe to the interval observable
        this.intervalSubscription = interval(intervalTime).subscribe(async () => {
          //Perform your desired action here
          await this.authService.authenticate();
        });
        //Get Authentication Token
        await this.authService.authenticate();
    } else{
      this.isIframe = window !== window.parent && !window.opener;
    }
  }

  setLoadCssFile(fileName: string): void {
    const cssfileref = document.createElement('link');
    cssfileref.setAttribute('rel', 'stylesheet');
    cssfileref.setAttribute('type', 'text/css');
    cssfileref.setAttribute('href', fileName);
    document.getElementsByTagName('head')[0].appendChild(cssfileref);
  }

  logout() {
    this.sharedService.logoutEvent.next(true);
  }


  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }


  setAppConfiguration() {
    environment.cKey = "letreb-O-neets-NewArch-1901-2020";
  }
}
